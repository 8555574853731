export function serialize (form) {
  var values = []

  for (const field of form.elements) {
    // Skip fields which cannot / ought not be serialized into GET requests
    if (
      field.disabled ||
      !field.name ||
      ['_method', 'authenticity_token', 'id'].includes(field.name) ||
      new RegExp('\\[id\\]$').test(field.name) ||
      ['button', 'file', 'reset', 'submit'].includes(field.type)
    ) continue

    if (field.type === 'select-multiple') { // Get all options of multi-select
      for (const option of field.options) {
        if (!option.selected) continue
        values.push(
          encodeURIComponent(field.name) +
          '=' +
          encodeURIComponent(option.value)
        )
      }
    } else if (
      field.checked ||
      (field.type !== 'checkbox' && field.type !== 'radio')
    ) { // Get all other options which should be submitted
      values.push(
        encodeURIComponent(field.name) +
        '=' +
        encodeURIComponent(field.value)
      )
    }
  }

  return values.join('&').replace(/%20/g, '+')
}
