import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['link', 'content']

  connect() {
    if(this.hasLinkTarget) {
      this.contentUrl = this.linkTarget.getAttribute('data-content-url')
    }
  }

  loadContent() {
    if (!this.data.get('loaded')) {
      this.linkTarget.classList.add('loading')

      Rails.ajax({
        type: 'GET',
        url: this.contentUrl,
        success: (content) => {
          this.onloadSuccess(content)
        },
        error: () => {
          this.onLoadError()
        }
      })
    }
  }

  onloadSuccess(content) {
    this.contentTarget.innerHTML = content.body.innerHTML
    this.linkTarget.classList.remove('loading')
    this.linkTarget.classList.add('loaded')
    this.data.set('loaded', true)
  }

  onLoadError() {
    this.linkTarget.classList.remove('loading')
    this.contentTarget.innerHTML = ''
    this.contentTarget.appendChild(
      document.createTextNode('Error when attempting to load content')
    )
  }
}
