import { Controller } from 'stimulus'
import { serialize } from '~/modules/serialize'

export default class extends Controller {
  static targets = ['bulkActionsContainer', 'checkbox', 'form', 'select', 'submitButton', 'submitButtonPlaceholder', 'submitFailure', 'deployButton', 'deploySelect']

  handleInputChange(event) {
    switch (event.target.type) {
      case 'select-one':
        this.updateSubmitButtonEnabled()
        break
      case 'checkbox':
        this.updateBulkActionsDisplay()
        break
    }
  }

  deploy(event) {
    event.preventDefault()
    var url = this.deployButtonTarget.getAttribute('data-href')
    var method = 'POST'
    if (this.deploySelectTarget.value != 'new') {
      url = url + '/' + this.deploySelectTarget.value
      method = 'PUT'
    }
    Rails.ajax({
      type: method,
      url: url + '?' + serialize(this.formTarget)
    })
  }

  submit(event) {
    event.preventDefault()

    this.submitButtonTarget.classList.add('d-none')
    this.submitButtonPlaceholderTarget.classList.remove('d-none')
    this.submitFailureTarget.classList.add('d-none')

    Rails.ajax({
      type: 'POST',
      url: this.formTarget.action + '?' + serialize(this.formTarget),
      success: (content) => {
        Rails.ajax({
          type: 'GET',
          url: this.formTarget.getAttribute('data-refresh-path'),
          success: (content) => {
            this.formTarget.innerHTML = content.body.innerHTML
          }
        })
      },
      error: (content) => {
        this.submitFailureTarget.classList.remove('d-none')
        this.submitButtonTarget.classList.remove('d-none')
        this.submitButtonPlaceholderTarget.classList.add('d-none')
      }
    })
  }

  updateSubmitButtonEnabled() {
    if (this.selectTargets.some(function(select){ return select.value })) {
      this.submitButtonTarget.removeAttribute('disabled')
    } else {
      this.submitButtonTarget.setAttribute('disabled', 'disabled')
    }
  }

  updateBulkActionsDisplay() {
    if (this.checkboxTargets.some(function(checkBox){ return checkBox.checked })) {
      this.bulkActionsContainerTarget.classList.remove('d-none')
    } else {
      this.bulkActionsContainerTarget.classList.add('d-none')
    }
  }
}
