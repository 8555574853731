import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['headerCheckbox', 'rowCheckbox']

  toggleHeaderCheckbox(event) {
    this.rowCheckboxTargets.forEach(function(checkBox){
      checkBox.checked = event.target.checked
    })
  }

  toggleRowCheckbox(event) {
    this.headerCheckboxTarget.checked = this.rowCheckboxTargets.every(function(checkBox){
      return checkBox.checked
    })
  }
}
