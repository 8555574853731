import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['template', 'linkContainer']

  addRuleSet(event) {
    event.preventDefault()
    var content = this.templateTarget.innerHTML.replace(/NEW_RULE_SET_RECORD/g, new Date().getTime())
    this.linkContainerTarget.insertAdjacentHTML('beforebegin', content)
  }
}
