import { Controller } from 'stimulus'

// Primarily exists to prevent previews which won't render results
export default class extends Controller {
  static targets = ['previewButton', 'fieldsWrapper']

  connect() {
    this.fields = this.fieldsWrapperTarget.querySelectorAll('input[type="checkbox"]')
    this.checkPreviewable()
  }

  checkPreviewable() {
    this.previewButtonTarget.classList.toggle('disabled', !this.anyFieldsChecked())
  }

  anyFieldsChecked() {
    return Array.from(this.fields).some(function(field) {
      return field.checked
    })
  }

}
