import { Controller } from 'stimulus'

// Primarily exists to catch emitted events and handle things appropriately,
//   so that we don't have to pass JS callbacks and evaluate those
export default class extends Controller {
  static targets = ['prioritySource', 'priorityWrapper', 'statusSource', 'resolutionWrapper', 'assignedToUser','removeResolutionWrapper']

  updatePriority() {
    let newClass = this.prioritySourceTarget.getAttribute('data-ticket-title-class')
    this.priorityWrapperTarget.classList.remove('alert-info', 'alert-warning', 'alert-danger')
    this.priorityWrapperTarget.classList.add(newClass)
  }

  updateResolution() {
    let status = this.statusSourceTarget.getAttribute('data-status')
    let resolution = this.statusSourceTarget.getAttribute('data-resolution')
    if(status == 'Closed' || resolution) {
      this.resolutionWrapperTarget.classList.remove('d-none')
    } else {
      this.resolutionWrapperTarget.classList.add('d-none')
      this.resolutionWrapperTarget.querySelector('textarea').value = ''
      this.resolutionWrapperTarget.querySelector('div[data-target="toggle-edit-mode.viewBlock"]').innerHTML = ''
    }
    if (status == 'Closed' || status == "Resolved")
    {
      this.removeResolutionWrapperTarget.classList.add('d-none')
    } else {
      this.removeResolutionWrapperTarget.classList.remove('d-none')
    }
  }

  updateAssignedToUser(event) {
    if(event.detail) {
      this.assignedToUserTarget.closest('[data-target="dropdowns.container"]').innerHTML = event.detail
    }
  }
}
