import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['selectWrapper']

  soloSelects(event) {
    let target = event.target
    let allSelects = this.selectWrapperTarget.querySelectorAll('select')
    let selectedField = target.options[target.selectedIndex].getAttribute('data-filter-select')
    let chosenSelect = this.selectWrapperTarget.querySelector("[data-filter-select='" + selectedField + "']")

    allSelects.forEach((select) => {
      select.setAttribute('disabled', 'disabled')
    })
    chosenSelect.removeAttribute('disabled')
  }
}
