import { Controller } from 'stimulus'
import { Tooltip } from 'bootstrap'

// When new Ajax content is loaded, connect up the Bootstrap tooltips
export default class extends Controller {
  static targets = ['container']

  connect() {
    let tooltipTriggerList = [].slice.call(this.containerTarget.querySelectorAll('[data-bs-toggle="tooltip"]'))
    for (let i = 0; i < tooltipTriggerList.length; i++) {
      new Tooltip(tooltipTriggerList[i])
    }
  }
}
