import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['file', 'form']

  new(event) {
    this.fileTarget.click()
    event.preventDefault()
  }

  create() {
    // TODO: Use Ajax so only the files section of the page is refreshed?
    this.formTarget.submit()
  }
}
