import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['container', 'error']

  connect() {
    this.stopPollingAfter = 60 * 60 * 1000  // 1 hour
    this.startTime = new Date()
    this.ajaxTimer = null
    this.url = this.containerTarget.getAttribute('data-ajax-refreshes-url')
    this.interval = this.containerTarget.getAttribute('data-ajax-refreshes-interval') * 1000
    this.performAjaxRequestAfterDelay()
  }

  pollingExpired() {
    let now = new Date()
    let elapsed = now - this.startTime

    // Stop polling if they've been sitting on the page for too long
    return elapsed > this.stopPollingAfter
  }

  performAjaxRequestAfterDelay() {
    if (this.pollingExpired()) { return }

    if (this.ajaxTimer) { clearTimeout(this.ajaxTimer) }
    this.ajaxTimer = setTimeout(() => { this.performAjaxRequest() }, this.interval)
  }

  performAjaxRequest() {
    Rails.ajax({
      type: 'GET',
      url: this.url,
      dataType: 'json',
      success: (data) => { this.ajaxSuccess(data) },
      error: () => { this.ajaxError() }
    })
  }

  ajaxSuccess(data) {
    this.containerTarget.innerHTML = data.html

    // If the server hasn't indicated we're done, keep polling.
    if (!data.done) { this.performAjaxRequestAfterDelay() }
  }

  ajaxError() {
    // If we hit an unexpected error, do nothing. They may have temporarily lost the connection.
    // Keep polling until we reach the timeout or the server says to stop.
    this.performAjaxRequestAfterDelay()
  }
}
