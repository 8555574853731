import { Controller } from 'stimulus'
import Chart from 'chart.js/auto'

export default class extends Controller {
  static targets = ['canvas', 'colorSource']

  connect() {
    let color = window.getComputedStyle(this.colorSourceTarget).backgroundColor
    let ctx = this.canvasTarget.getContext('2d')
    let chart = new Chart(ctx, {
      type: 'bar',
      data: {
          labels: JSON.parse(this.canvasTarget.getAttribute('data-labels')),
          datasets: [
            {
              label: this.canvasTarget.getAttribute('data-title'),
              backgroundColor: color,
              borderColor: color,
              data: JSON.parse(this.canvasTarget.getAttribute('data-data'))
            }
          ],
      },
      options: {
        // Don't let the graph height shrink to a tiny size if the viewport width is small
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false }
        },
        scales: {
          y: {
            display: true,
            ticks: {
              // Don't show negative scale on y-axis when all counts are zero
              beginAtZero: true,
              // Don't show non-integer tick values on the y-axis
              callback: function(value) { if (value % 1 === 0) { return value } }
            }
          }
        }
      }
    });
  }
}
