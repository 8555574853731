import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form']

  submit(event) {
    event.preventDefault()
    this.formTarget.submit()
  }
}
