import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['select', 'content']

  change() {
    let option = this.selectTarget.options[this.selectTarget.selectedIndex]
    let show = option.getAttribute('data-collapse-selects-show') == 'true'
    this.contentTarget.classList.toggle('d-none', !show)
  }
}
