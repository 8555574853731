import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['displayValue', 'formContainer', 'form', 'input']

  connect() {
    this.initialValue = this.inputTarget.value
  }

  show() {
    if (this.data.get('editing')) { return }

    this.displayValueTarget.classList.add('d-none')
    this.formContainerTarget.classList.remove('d-none')
    this.inputTarget.focus()
    this.data.set('editing', true)
  }

  hide() {
    this.displayValueTarget.innerText = this.initialValue
    this.formContainerTarget.classList.add('d-none')
    this.displayValueTarget.classList.remove('d-none')
    this.data.delete('editing')
  }

  submit() {
    if (this.inputTarget.value === this.initialValue) {
      this.hide()
    } else {
      this.formTarget.dispatchEvent(new Event('submit', { bubbles: true }))
    }
  }

  onUpdateSuccess(event) {
    this.formTarget.classList.remove('error')
    this.initialValue = this.inputTarget.value
    this.hide()
  }

  onUpdateError(event) {
    this.formTarget.classList.add('error')
  }
}
