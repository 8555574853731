import { Controller } from 'stimulus'
import { serialize } from '~/modules/serialize'

export default class extends Controller {
  static targets = ['form', 'wrapper', 'previewButton']

  connect() {
    this.baseUrl = this.data.get('url')
    this.refreshText = this.data.get('refresh-text')
    this.errorText = this.data.get('error-text')
  }


  loadContent(event) {
    let target = event.target
    if (target.disabled || target.classList.contains('disabled')) { return }
    let contentUrl = this.baseUrl + '?' +serialize(this.formTarget)

    Rails.ajax({
      type: 'GET',
      url: contentUrl,
      success: (content) => {
        this.onloadSuccess(content)
      },
      error: () => {
        this.onLoadError()
      }
    })
  }

  onloadSuccess(content) {
    this.wrapperTarget.innerHTML = content.body.innerHTML
    this.previewButtonTarget.innerHTML = this.refreshText
  }

  onLoadError() {
    this.wrapperTarget.innerHTML = this.errorText
  }
}
