import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['textarea']

  connect() {
    this.minimumHeight = 92
    this.textareaTarget.addEventListener('focus', function() { this.resizeToContentHeight() }.bind(this))
    this.textareaTarget.addEventListener('input', function() { this.resizeToContentHeight() }.bind(this))
  }

  resizeToContentHeight() {
    this.textareaTarget.style.height = Math.max(this.minimumHeight, this.textareaTarget.scrollHeight) + 'px'
  }

  paste(clipboardEvent) {
    let items = clipboardEvent.clipboardData.items
    for(let item of items){
      if (item.type.indexOf("image") === 0) {
        this.upload_image(item.getAsFile())
      }
    }
  }

  upload_image(image) {
    let formdata = new FormData()
    formdata.append('file', image )

    let existing_text_area = this.textareaTarget.closest("div[data-controller*='attachments']")
    if(!existing_text_area) return // For now, attachment can only be linked to existing description/comment

    let url = existing_text_area.querySelector('form#new_attachment').action
    Rails.ajax({
      type: 'POST',
      url: url,
      data: formdata,
      format: 'json',
      success: this.insert_markdown_line.bind(this)
    })
  }

  insert_markdown_line(data) {
    let markdown_tag = `![${data.filename}](${data.url})`
    let comment = this.textareaTarget.value
    let position = this.textareaTarget.selectionEnd
    this.textareaTarget.value = [comment.slice(0, position), markdown_tag, comment.slice(position)].join('')
  }
}
