import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['append', 'delete', 'error', 'replace', 'text']

  connect() {
    this.successTarget = this.resolveSuccessTarget()
    const obs = new MutationObserver(this.dom_changed);
    obs.observe(this.successTarget, { attributes: false, childList: true, subtree: false })
  }

  dom_changed(mutationsList, observer) {
    for(let div of mutationsList[0].addedNodes) {
      if (div.querySelector('.prettyprint')) {
        PR.prettyPrint()
      }
    }
  }

  append(event) {
    let [data, status, xhr] = event.detail
    this.toggleErrorClass(false)
    this.successTarget.insertAdjacentHTML('beforeend', this.responseHtml(data))
    if(this.hasTextTarget) {
      this.textTarget.value = ''
    }
  }

  error(event) {
    let [data, status, xhr] = event.detail
    this.toggleErrorClass(true)
  }

  remove(event) {
    this.successTarget.parentNode.removeChild(this.deleteTarget)
  }

  replaceContent(event) {
    let [data, status, xhr] = event.detail
    let emitEvent = new Event('doneEditing', { bubbles: true })
    this.toggleErrorClass(false)
    this.successTarget.innerHTML = this.responseHtml(data)
    this.successTarget.dispatchEvent(emitEvent)
  }

  resolveSuccessTarget() {
    if(this.hasReplaceTarget) {
      return this.replaceTarget
    } else if(this.hasAppendTarget) {
      return this.appendTarget
    } else if(this.hasDeleteTarget) {
      return this.deleteTarget
    } else {
      return document.querySelector(this.data.get('success-selector'))
    }
  }

  toggleErrorClass(hasError) {
    if(this.hasErrorTarget) {
      this.errorTarget.classList.toggle('ajax-error', hasError)
    }
  }

  responseHtml(data) {
    return data.html || data.body.innerHTML
  }

  refreshContent() {
    let event = new Event('ajax-load:refresh')
    let container = this.successTarget.closest('[data-target*="ajax-loads.container"]')
    container.dispatchEvent(event)
  }
}
