export function debounce (callback, ms) {
  var timeout
  return function () {
    var args = arguments
    var context = this
    clearTimeout(timeout)
    timeout = setTimeout(function () {
      callback.apply(context, args)
    }, ms)
  }
}
