import { Controller } from 'stimulus'

// This controller handles menu dropdowns in conjunction with the
// dropdown component provided by Bootstrap 5:
// https://getbootstrap.com/docs/5.0/components/dropdowns/
export default class extends Controller {
  static targets = ['container', 'menu', 'dropdown']

  callback(target, detail) {
    let callbackNames = target.getAttribute('data-callback')
    if(callbackNames) {
      callbackNames.trim().split(' ').forEach(function(callbackName){
        if(callbackName){
          target.dispatchEvent(new CustomEvent(callbackName, { bubbles: true, detail: detail }))
        }
      })
    }
  }

  onUpdateSuccess(event) {
    let resp = event.detail[0]
    this.containerTarget.classList.remove('error')
    this.containerTarget.innerHTML = resp.html
    this.callback(this.dropdownTarget, resp.callback_payload)
  }

  onUpdateError(event) {
    this.containerTarget.classList.add('error')
  }

  // We need to hide the dropdown before Turbolinks cached the page or else the dropdown
  // will flicker open when links are used.
  //
  //   Listen for the turbolinks:before-cache event if you need to prepare the document before
  //   Turbolinks caches it. You can use this event to reset forms, collapse expanded UI elements,
  //   or tear down any third-party widgets so the page is ready to be displayed again.
  //   https://github.com/turbolinks/turbolinks#preparing-the-page-to-be-cached
  //
  turbolinksBeforeCache() {
    this.menuTarget.classList.remove('show')
  }
}
