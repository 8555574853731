import { Notifier } from '@airbrake/browser'
import "@rails/ujs" // Autostarts
import * as Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "../channels"
import "../controllers"
import "../bootstrap_components/init_components"

Turbolinks.start()
ActiveStorage.start()

let projectKey = document.getElementById('airbrake-key')
let projectEnv = document.getElementById('airbrake-env')

if (projectKey) {
  let airbrake = new Notifier({
    projectId: 247303,
    projectKey: projectKey.getAttribute('data-key'),
    environment: projectEnv.getAttribute('data-env'),
    remoteConfig: false,
    performanceStats: false,
    queryStats: false,
    queueStats: false
  })

  window.airbrake = airbrake
}
