import { Controller } from 'stimulus'
import { debounce } from '~/modules/debounce'

export default class extends Controller {
  static targets = ['dropdown', 'error', 'input', 'link', 'template', 'text']

  connect() {
    this.searchUrl = this.data.get('searchUrl')
    this.limitParam = this.data.get('limitParam')
    this.limitValue = this.data.get('limitValue') || 5
    this.searchParam = this.data.get('searchParam')
    this.minimumLength = 2
    this.previousInput = ''
  }

  closeDropdown() {
    this.toggleDropdown(true)
  }

  openDropdown() {
    this.toggleDropdown(false)
  }

  selectOption(event) {
    this.textTarget.value = event.currentTarget.text
    this.inputTarget.value = event.currentTarget.getAttribute('data-autocomplete-value')
    this.toggleErrorClass(false)
    this.closeDropdown()
  }

  toggleDropdown(show) {
    this.dropdownTarget.classList.toggle('d-none', show)
  }

  error(event) {
    let [data, status, xhr] = event.detail
    this.toggleErrorClass(true)
  }


  toggleErrorClass(hasError) {
    if(this.hasErrorTarget) {
      this.errorTarget.classList.toggle('ajax-error', hasError)
    }
  }

  onDocumentClick(event) {
    let target = event.target
    if (!(this.dropdownTarget.contains(target) || this.textTarget.contains(target))) {
      this.closeDropdown()
    }
  }

  turbolinksBeforeCache() {
    this.closeDropdown()
  }


  updateDropdown(response) {
    this.dropdownTarget.innerHTML = ''

    response.forEach((item) => {
      let itemElement = document.importNode(this.templateTarget.content, true)
      itemElement.querySelector('a').innerHTML = item.display_value
      itemElement.querySelector('a').setAttribute('data-autocomplete-value', item.selected_value)
      this.dropdownTarget.appendChild(itemElement)
    })
  }

  doSearch = debounce(function (event) {
    let currentInput = event.target.value;

    // ensure minimum input length
    if (currentInput.length > this.minimumLength) {
      // don't send duplicate requests
      if (currentInput !== this.previousInput) {
        this.toggleErrorClass(false)

        Rails.ajax({
          type: 'GET',
          url: this.searchUrl + '?' + this.constructUrl(currentInput),
          success: (response) => {
            this.updateDropdown(response)
          },
          error: (response) => {
            this.error(event)
          }
        })

        // update previous input
        this.previousInput = currentInput
      }
    }
  }, 750)

  constructUrl(currentInput) {
    let params = new URLSearchParams()
    params.append(this.limitParam, this.limitValue)
    params.append(this.searchParam, currentInput)
    return params.toString()
  }

}
