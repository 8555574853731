import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['wrapper']

  connect() {
    this.errorText = this.data.get('error-text')
  }

  loadPage(event) {
    event.preventDefault()
    Rails.ajax({
      type: 'GET',
      url: event.target.getAttribute('href'),
      success: (content) => {
        this.wrapperTarget.innerHTML = content.body.innerHTML
      },
      error: () => {
        this.wrapperTarget.innerHTML = this.errorText
      }
    })
  }
}
