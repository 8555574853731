import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['template', 'ruleContainer']

  addRule(event) {
    event.preventDefault()
    var content = this.templateTarget.innerHTML.replace(/NEW_RULE_RECORD/g, new Date().getTime())
    event.target.insertAdjacentHTML('beforebegin', content)
  }

  removeRule(event) {
    event.preventDefault()
    this.removeFromUI(event.target.closest("[data-target='rules.ruleContainer']"))
    if (this.ruleContainerTargets.length == 0) {
      this.removeFromUI(this.element)
    }
  }

  removeFromUI(element) {
    if (element.dataset.new_record === 'true') {
      element.remove()
    } else {
      delete element.dataset['target']
      element.classList.add('d-none')
      element.querySelectorAll('input[name*="_destroy"]').forEach(function(input){
        input.value = true
      })
    }
  }
}
