import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['container', 'loading', 'error', 'searchField', 'select', 'additionalParam']

  connect() {
    this.url = this.containerTarget.getAttribute('data-ajax-loads-url')
    this.loadType = this.containerTarget.getAttribute('data-ajax-loads-type')
    this.loading = false
    this.loaded = false
    this.ajaxTimer = null
    this.evaluateLoadType()

    this.containerTarget.addEventListener('ajax-load:refresh', function () {
      this.refresh()
    }.bind(this))
  }

  evaluateLoadType() {
    switch (this.loadType) {
      case 'now':
        this.loadOnce()
        break
      case 'dropdown':
        this.handleDropdownShow()
        break
      case 'modal':
        this.handleModalShow()
        break
      case 'collapse':
        this.handleCollapseShow()
        break
      case 'select':
        // Do nothing. The select control will use a standard data-action to trigger a refresh.
        break
      case 'manual':
        // Do nothing. Only manually dispatched "ajax-load:refresh" events will trigger an Ajax load.
        break
    }
  }

  handleDropdownShow() {
    this.containerTarget.closest('.dropdown').addEventListener('show.bs.dropdown', function (event) {
      this.loadOnce()
    }.bind(this))
  }

  handleModalShow() {
    this.containerTarget.closest('.modal').addEventListener('show.bs.modal', function (event) {
      this.loadOnce()
    }.bind(this))
  }

  handleCollapseShow() {
    this.containerTarget.closest('.collapse').addEventListener('show.bs.collapse', function (event) {
      this.loadOnce()
    }.bind(this))
  }

  loadOnce() {
    if (this.loading || this.loaded) { return }

    this.performAjaxRequest()
  }

  performAjaxRequest(searchTerm) {
    let data = null
    if (this.hasSearchFieldTarget) {
      data = this.buildParam(this.searchFieldTarget.name, searchTerm)
    }
    if (this.hasSelectTarget) {
      data = this.buildParam(this.selectTarget.getAttribute('data-ajax-loads-name'), this.selectTarget.value)
    }
    if (this.hasAdditionalParamTarget) {
      data = this.additionalParamTargets.map((target) => {
        return this.buildParam(target.getAttribute('data-ajax-loads-name'), target.value);
      }).join('&')
    }

    this.ajaxStart()
    Rails.ajax({
      type: 'GET',
      url: this.url,
      data: data,
      dataType: 'html',
      success: (html) => { this.ajaxSuccess(html) },
      error: () => { this.ajaxError() },
      complete: () => { this.loading = false }
    })
  }

  onSearchInput(event) {
    this.reloadAfterDelay(this.searchFieldTarget.value)
  }

  reloadAfterDelay(searchTerm) {
    if (this.ajaxTimer) { clearTimeout(this.ajaxTimer) }
    this.ajaxTimer = setTimeout(() => {
      this.beforeReload()
      this.performAjaxRequest(searchTerm)
    }, 200)
  }

  buildParam(name, value) {
    return name + '=' + (value ? encodeURIComponent(value) : '')
  }

  beforeReload() {
    this.containerTarget.classList.add('d-none')
  }

  ajaxStart() {
    this.loading = true
    this.loadingTarget.classList.remove('d-none')
  }

  ajaxSuccess(html) {
    this.loadingTarget.classList.add('d-none')
    this.errorTarget.classList.add('d-none')
    this.containerTarget.classList.remove('d-none')
    this.containerTarget.innerHTML = html.body.innerHTML
    this.loaded = true
  }

  ajaxError() {
    this.loadingTarget.classList.add('d-none')
    this.errorTarget.classList.remove('d-none')
  }

  refresh() {
    this.beforeReload()
    this.performAjaxRequest()
  }
}
